


































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Sticky, Tab, Tabs, Row } from "vant";
import SpecialTheme from "./Index.vue";
import { wxShare } from "@/utils/wechat";
@Component({
  components: {
    SpecialTheme,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
  },
})
export default class dramaFestival extends Mixins(Mixin) {
  public active = "";
  public sticky = false;
  public shareInfo = {
    title: "保利戏聚节 · 哈尔滨/沈阳/辽阳 分会场",
    desc: "50元剧院优惠券限时发放，囤券购票享折上折",
    imgUrl: "https://cdn.polyt.cn/png/20230626150809.png",
    link: `${location.origin}${location.pathname}#/drama-theme`,
  };
  mounted(): void {
    // window.requestAnimationFrame会在每一帧渲染之前执行一次，因此可以多次执行。可以使用递归调用来实现多次执行。
    this.$nextTick(() => {
      window.requestAnimationFrame(this.pagerScroll);
    });
    wxShare(
      this.shareInfo.title,
      this.shareInfo.desc,
      this.shareInfo.imgUrl,
      this.shareInfo.link
    );
    this.jsBridge("isShowShare", "1");
    window.startShare = this.share;
  }
  pagerScroll() {
    let dramaFestival = document.querySelector("#dramaFestival");
    dramaFestival?.addEventListener("scroll", () => {
      this.sticky = true;
    });
  }
  share(): void {
    this.jsBridge(
      "share",
      `title%&@/${this.shareInfo.title}%&@,desc%&@/${this.shareInfo.desc}%&@,picUrl%&@/${this.shareInfo.imgUrl}%&@,url%&@/${this.shareInfo.link}`
    );
  }
}
