

























import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Button } from "vant";

@Component({
  components: {
    [Button.name]: Button,
  },
})
export default class SpecialCoupon extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public item!: cms.SubjectCouponInfoDto;
  receive() {
    this.$emit("receive", this.item);
  }
}
