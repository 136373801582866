
























































































































































































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Sticky, Tab, Tabs, List, Popover, Button } from "vant";
import productLayout1 from "./components/productLayout1.vue";
import productLayout2 from "./components/productLayout2.vue";
import productLayout3 from "./components/productLayout3.vue";
import productLayout4 from "./components/productLayout4.vue";
import WaterfallLayout from "@/views/Mall/Details/waterfallLayout.vue";
import mallProList from "@/components/mallProList.vue";
import couponLayout1 from "./components/couponLayout1.vue";
import couponLayout2 from "./components/couponLayout2.vue";
import couponLayout3 from "./components/couponLayout3.vue";
import couponLayout4 from "./components/couponLayout4.vue";
import { wxShare } from "@/utils/wechat";

@Component({
  components: {
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Popover.name]: Popover,
    [Button.name]: Button,
    productLayout1,
    productLayout2,
    productLayout3,
    productLayout4,
    WaterfallLayout,
    mallProList,
    couponLayout1,
    couponLayout2,
    couponLayout3,
    couponLayout4,
  },
})
export default class SpecialTheme extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public propSpecialId!: string;
  specialId = "";
  containerMap: any = {};
  subjectInfo: cms.SubjectInfoDetailDto = {
    specialColor: "",
    specialName: "",
  };
  layoutList: cms.SubjectLayoutsDto[] = [];
  productMap: Record<string, any> = {};
  goodsMap: Record<string, any> = {};

  @Watch("$route")
  changeRoute(to: Route): void {
    this.specialId = String(this.$route.query.id);
    this.getData();
  }
  mounted() {
    this.specialId = this.$route.query.id
      ? String(this.$route.query.id)
      : this.propSpecialId;
    this.getData();
  }

  share(): void {
    const title = this.subjectInfo.shareTitle;
    const desc = this.subjectInfo.shareSubTitle;
    const imgUrl = this.subjectInfo.shareImg;
    const link = `${location.origin}${location.pathname}#/special-theme?id=${this.specialId}`;
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${desc}%&@,picUrl%&@/${imgUrl}%&@,url%&@/${link}`
    );
  }
  getData() {
    this.$api.cmsApi.subject.findNewSubjectInfoById(
      this.specialId,
      ({ data }) => {
        this.subjectInfo = data;
        // 戏剧节 title
        window.document.title =
          this.$route.query.id && this.subjectInfo.specialName
            ? this.subjectInfo.specialName
            : "爱演出 爱生活-保利票务";
        if (data.shareTitle) {
          wxShare(
            this.subjectInfo.shareTitle!,
            this.subjectInfo.shareSubTitle!,
            this.subjectInfo.shareImg!,
            `${location.origin}${location.pathname}#/special-theme?id=${this.specialId}`
          );
          this.jsBridge("isShowShare", "1");
          window.startShare = this.share;
        }
        this.layoutList = data.layouts!;
        this.layoutList.forEach((item: any) => {
          if (item.layoutType === "02") {
            let productInfo: any = {
              loading: false,
              finished: item.recommendMode === "01",
              page: 0,
              productList: item.subjectProducts,
              navigationId: "",
              navList: [],
              showMore: false,
            };
            this.$set(this.productMap, item.subjectLayoutId, productInfo);
            if (item.recommendMode === "02") {
              this.onLoadProduct(item.subjectLayoutId);
              if (item.navigation) {
                this.$api.cmsApi.subject.getSubjectProductNavigation(
                  item.subjectLayoutId,
                  ({ data }) => {
                    this.productMap[item.subjectLayoutId].navList = data.map(
                      (item) => {
                        if (item.navigationId === "440309") {
                          item.navigationName = "推荐";
                        }
                        return item;
                      }
                    );
                  }
                );
              }
            }
          } else if (item.layoutType === "03") {
            let goodsInfo = {
              loading: false,
              finished: item.recommendMode === "03",
              page: 0,
              goodsList: item.subjectGoods,
            };
            this.$set(this.goodsMap, item.subjectLayoutId, goodsInfo);
            if (item.recommendMode !== "03") {
              this.onLoadGoods(item.subjectLayoutId);
            }
          }
        });
      }
    );
  }
  changeTab(subjectLayoutId: string) {
    const id = subjectLayoutId;
    this.productMap[id].page = 0;
    this.onLoadProduct(id);
  }

  changeMoreTab(navigationId: string, subjectLayoutId: string) {
    const productInfo = this.productMap[subjectLayoutId];
    productInfo.navigationId = navigationId;
    productInfo.showMore = false;
    this.changeTab(subjectLayoutId);
  }

  getContainer(id: string) {
    let ref: any = this.$refs[`container${id}`];
    this.$set(this.containerMap, id, ref[0]);
  }

  onLoadProduct(id: string) {
    const productInfo = this.productMap[id];
    productInfo.page++;
    if (productInfo.page === 1) {
      productInfo.productList = [];
    }
    productInfo.loading = true;
    this.$api.cmsApi.subject.getSubjectProducts(
      {
        specialLayoutId: id,
        navigationId: productInfo.navigationId,
        page: productInfo.page,
        size: 10,
      },
      ({ data }) => {
        productInfo.loading = false;
        productInfo.productList.push(...data.records!);
        productInfo.finished = productInfo.page >= data.pages!;
        this.$nextTick(() => {
          this.getContainer(id);
        });
      }
    );
  }
  onLoadGoods(id: string) {
    const goodsInfo = this.goodsMap[id];
    goodsInfo.page++;
    if (goodsInfo.page === 1) {
      goodsInfo.goodsList = [];
    }
    goodsInfo.loading = true;
    this.$api.cmsApi.subject.getSubjectGoods(
      {
        specialLayoutId: id,
        page: goodsInfo.page,
        size: 10,
      },
      ({ data }) => {
        goodsInfo.loading = false;
        goodsInfo.goodsList.push(...data.records!);
        goodsInfo.finished = goodsInfo.page >= data.pages!;
      }
    );
  }
  receiveCoupon(item: cms.SubjectCouponInfoDto) {
    if (!this.UserModule.isLogin) {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
    } else {
      if (item.alreadyReceiveQuantity === item.quantity) {
        return;
      }
      this.$api.goodApi.coupon.receiveCoupon(
        item.couponId!,
        {
          activityId: item.activityId!,
        },
        ({ data }) => {
          if (data) {
            this.$toast("领取成功");
          } else {
            this.$toast("领取失败");
          }
        }
      );
    }
  }
  gotoMall(item: cms.SubjectGoodsDto) {
    this.goto({ linkId: item.commodityInfoId, linkType: "SHOP_COMMODITY" });
  }
  gotoProduct(item: cms.SubjectProductsDto) {
    this.goto({ linkId: item.productId, linkType: "PRODUCT_LINK" });
  }
  remindProduct(item: cms.SubjectProductsDto) {
    if (!this.UserModule.isLogin) {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
    } else {
      this.$api.memberApi.attentionAndCollection.userSaleRemind(
        Number(item.productId),
        Number(item.saleBeginTime),
        ({ data }) => {
          if (data) {
            this.$toast("提醒设置成功，开票前15分钟，将收到开票提醒消息");
          } else {
            this.$toast("提醒设置失败");
          }
        }
      );
    }
  }
  goto(item: any) {
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }
}
