






























































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Image, Row, Col, Icon } from "vant";

@Component({
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
})
export default class productLayout1 extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<cms.SubjectProductsDto>;

  goProDetail(item: cms.SubjectProductsDto): void {
    this.$emit("goProDetail", item);
  }
  remind(item: cms.SubjectProductsDto) {
    this.$emit("remind", item);
  }
}
