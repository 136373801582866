











import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import coupon from "./coupon.vue";

@Component({
  components: {
    coupon,
  },
})
export default class CouponLayout1 extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public couponList!: cms.SubjectCouponInfoDto[];

  receive(item: cms.SubjectCouponInfoDto) {
    this.$emit("receive", item);
  }
}
